import { clsxMerge } from 'src/helpers/utilities'

interface NfdTagInterface {
  label: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: (props: any) => JSX.Element
  iconClassName?: string
  capitalize?: boolean
  margin?: boolean
  className?: string
}

export type NfdTagColors = 'gray' | 'blue' | 'green' | 'yellow' | 'red' | 'slate'

export type NfdTag = (
  | {
      color: NfdTagColors
      brand?: never
    }
  | {
      color?: never
      brand: boolean
    }
) &
  NfdTagInterface

export const ResultTag = ({
  label,
  icon,
  iconClassName = '',
  capitalize = false,
  margin = false,
  color,
  brand,
  className = ''
}: NfdTag) => {
  const colors = {
    gray: 'bg-gray-100 text-gray-800 dark:bg-gray-700/50 dark:text-gray-300',
    blue: 'bg-blue-100 text-blue-800 dark:bg-gray-300/10 dark:text-blue-300',
    green: 'bg-green-100 text-green-800 dark:bg-gray-300/10 dark:text-green-300',
    yellow: 'bg-amber-100 text-amber-800 dark:bg-gray-300/10 dark:text-amber-300',
    red: 'bg-red-500 text-white dark:bg-red-600 dark:text-red-100',
    slate: 'bg-gray-500 text-white dark:bg-gray-600 dark:text-gray-100'
  }

  const tagClasses = clsxMerge(
    'inline-flex items-center px-2 py-0.5 rounded text-xs font-medium whitespace-nowrap',
    brand ? 'bg-brand-500 text-white font-bold' : '',
    color ? colors[color] : '',
    capitalize ? 'capitalize' : '',
    margin ? 'mr-2' : '',
    className
  )

  const Icon = ({ className = '' }) => (icon ? icon({ className }) : null)

  return (
    <span className={tagClasses}>
      {icon && (
        <Icon
          className={clsxMerge(
            'h-3 w-3 -ml-0.5 mr-1',
            brand ? 'text-white' : color ? colors[color].split(' ')[1] : '',
            iconClassName
          )}
          aria-hidden="true"
        />
      )}
      {label}
    </span>
  )
}

interface ResultTagsProps {
  tags: Array<NfdTag & { label: string }>
  limit?: number
  tagClassName?: string
}

export const ResultTags = ({ tags, limit = 0, tagClassName = '' }: ResultTagsProps) => (
  <>
    {tags.map((tag, i) => {
      if (limit && i >= limit) {
        return null
      }

      return <ResultTag key={tag.label} margin className={tagClassName} {...tag} />
    })}
  </>
)
