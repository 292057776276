import { classNames } from 'helpers/utilities'
import { gridProperties } from './ItemLayout.constants'
import type { ViewSetting } from 'components/Dashboard/Controls'

interface ItemLayoutProps {
  view: ViewSetting
  children: React.ReactNode
  className?: string
  layoutClassName?: string
}

export default function ItemLayout({
  view,
  children,
  className = '',
  layoutClassName = ''
}: ItemLayoutProps) {
  const gridClasses =
    'grid grid-cols-[repeat(auto-fill,_minmax(160px,_1fr))] xl:grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))]'

  const { columnGap, rowGap } = gridProperties

  const gridStyle = {
    columnGap: `${columnGap}px`,
    rowGap: `${rowGap}px`
  }

  const isList = view === 'list'

  const layoutStyle = isList ? {} : gridStyle

  return (
    <div className={classNames(isList ? 'bg-white shadow dark:bg-gray-900' : '', className)}>
      <div
        className={classNames(
          isList ? 'divide-y divide-gray-200 dark:divide-gray-800' : gridClasses,
          layoutClassName
        )}
        style={layoutStyle}
      >
        {children}
      </div>
    </div>
  )
}
