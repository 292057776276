import { useQuery } from '@tanstack/react-query'
import axios, { type AxiosError } from 'axios'
import queryString from 'query-string'
import { useMemo } from 'react'
import { getAccountInfo } from 'helpers/node'
import { isAssetNft } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'
import type { Asset } from 'types/assets'

export type AssetTypeCount = Record<'nfts' | 'tokens', number>

export type VaultAssetsInfo = {
  assets: Array<Asset>
  assetsCount: number
  assetTypeCount: AssetTypeCount
}

export type VaultAssetsOptions = {
  enableQuery: boolean
}

export default function useVaultAssets(nfd: NfdRecord, options?: VaultAssetsOptions) {
  const { enableQuery = true } = options || {}
  const vaultAddress = nfd.nfdAccount

  const getVaultAssetIds = async () => {
    const accountInfo = await getAccountInfo(vaultAddress as string)
    const assetIds = accountInfo.assets?.map((asset) => asset['asset-id']) || []

    return assetIds
  }

  const getVaultAssets = async (totalAssets: number) => {
    const LIMIT = 100
    const numberOfRequests = Math.ceil(totalAssets / LIMIT)

    const promises = Array.from({ length: numberOfRequests }).map((_, index) => {
      return axios.get<Asset[]>(`/api/assets/${nfd.name}`, {
        params: {
          limit: LIMIT,
          offset: index * LIMIT,
          account: vaultAddress,
          filter: ['nfts', 'tokens']
        },
        paramsSerializer: (params) => queryString.stringify(params)
      })
    })

    const allAssetsResponses = await Promise.all(promises)
    const allAssets = allAssetsResponses
      .flatMap((response) => response.data)
      .filter((asset) => asset.id !== 0)

    return allAssets
  }

  const getVaultInfo = async (): Promise<VaultAssetsInfo> => {
    const assetIds = await getVaultAssetIds()
    // exclude NFD asset itself from count
    const totalAssets = assetIds.length - 1

    if (totalAssets === 0) {
      return {
        assets: [],
        assetsCount: 0,
        assetTypeCount: {
          nfts: 0,
          tokens: 0
        }
      }
    }

    const assets = await getVaultAssets(totalAssets)
    const nfts = assets.filter((asset) => isAssetNft(asset.totalCreated, asset.decimals))
    const tokens = assets.filter((asset) => !isAssetNft(asset.totalCreated, asset.decimals))

    return {
      assets,
      assetsCount: assets.length,
      assetTypeCount: {
        nfts: nfts.length,
        tokens: tokens.length
      }
    }
  }

  const {
    data,
    isInitialLoading: isLoading,
    error
  } = useQuery<VaultAssetsInfo, AxiosError>({
    queryKey: ['vaultAssetsInfo', nfd.name],
    queryFn: getVaultInfo,
    enabled: !!vaultAddress && enableQuery,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  })

  const { assets, assetsCount, assetTypeCount } = data || {}

  const showVaultInfo = useMemo(
    () => !isLoading && Number(assetsCount) > 0,
    [assetsCount, isLoading]
  )

  return { showVaultInfo, assets, assetsCount, assetTypeCount, isLoading, error }
}
