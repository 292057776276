import { classNames } from 'helpers/utilities'
import { HiViewGrid, HiOutlineViewList } from 'react-icons/hi'

interface GridListToggleProps {
  onChange: (value: 'grid' | 'list') => void
  value: 'grid' | 'list'
  className?: string
}

export default function GridListToggle({
  onChange,
  value,
  className = 'ml-6'
}: GridListToggleProps) {
  const defaultClassName =
    'p-1.5 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500 dark:text-gray-400'

  const activeClassName = 'bg-white shadow-sm dark:bg-gray-400/20 dark:highlight'
  const inactiveClassName = 'hover:bg-white hover:shadow-sm dark:hover:bg-gray-600/20'

  const gridClassName = classNames(
    value === 'grid' ? activeClassName : inactiveClassName,
    defaultClassName
  )

  const listClassName = classNames(
    value === 'list' ? activeClassName : inactiveClassName,
    defaultClassName,
    'ml-0.5'
  )

  return (
    <div
      className={classNames(
        'bg-gray-100 p-0.5 rounded-lg flex flex-nowrap items-center dark:bg-gray-500/10',
        className
      )}
    >
      <button
        type="button"
        className={gridClassName}
        onClick={() => onChange('grid')}
        tabIndex={value === 'grid' ? -1 : 0}
      >
        <HiViewGrid className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Use grid view</span>
      </button>
      <button
        type="button"
        className={listClassName}
        onClick={() => onChange('list')}
        tabIndex={value === 'list' ? -1 : 0}
      >
        <HiOutlineViewList className="h-5 w-5" aria-hidden="true" />
        <span className="sr-only">Use list view</span>
      </button>
    </div>
  )
}
