import { clsxMerge } from 'helpers/utilities'

const sizes = {
  sm: 'w-6 h-6',
  md: 'w-8 h-8',
  lg: 'w-10 h-10',
  xl: 'w-12 h-12',
  '2xl': 'w-16 h-16',
  '3xl': 'w-20 h-20',
  '4xl': 'w-24 h-24'
}

interface SpinnerProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: keyof typeof sizes
}

export function Spinner({ size = 'md', strokeWidth = 3, className, ...props }: SpinnerProps) {
  return (
    <svg
      {...props}
      className={clsxMerge('animate-spin text-gray-700 dark:text-gray-400', sizes[size], className)}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9" stroke="currentColor" opacity={0.15} />
      <path d="M12,3c5,0,9,4,9,9c0,0.9-0.1,1.7-0.4,2.5" stroke="currentColor" />
    </svg>
  )
}

interface LoadingProps extends React.ComponentPropsWithoutRef<'div'> {
  size?: keyof typeof sizes
  strokeWidth?: number
}

export function Loading({
  size = '2xl',
  strokeWidth = 2,
  className,
  children,
  ...props
}: LoadingProps) {
  return (
    <div {...props} className={clsxMerge('grid place-items-center w-full min-h-36', className)}>
      {children || (
        <Spinner
          size={size}
          strokeWidth={strokeWidth}
          className="text-gray-400 dark:text-gray-600"
        />
      )}
    </div>
  )
}
