import { HiOutlineBadgeCheck, HiCheck } from 'react-icons/hi'
import { classNames } from 'helpers/utilities'

export default function VerifiedBadge({ small = false, circle = false }) {
  if (circle) {
    return (
      <div className="inline-flex items-center justify-center p-0.5 ring ring-white shadow-md text-white bg-gradient-to-br from-violet-600 to-pink-500 rounded-full dark:ring-gray-900">
        <HiCheck className="h-3 w-3" aria-hidden="true" />
      </div>
    )
  }

  return (
    <div
      className={classNames(
        small ? 'ring-[5px] sm:ring-4' : 'ring',
        'inline-flex items-center justify-start w-24 h-24 pl-2 ring-white shadow-md text-white bg-gradient-to-r from-violet-600 to-pink-500 rotate-45 rounded-full'
      )}
    >
      {small ? (
        <HiCheck className="h-6 w-6 -rotate-45" aria-hidden="true" />
      ) : (
        <HiOutlineBadgeCheck className="h-6 w-6 -rotate-45" aria-hidden="true" />
      )}
    </div>
  )
}
