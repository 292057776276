import { useWallet } from '@txnlab/use-wallet-react'
import * as React from 'react'
import useName from 'api/hooks/useName'

type MintPollingStatus = 'idle' | 'polling' | 'success' | 'error'

interface UseMintPollingResult {
  startPolling: () => void
  status: MintPollingStatus
  error: Error | null
}

export function useMintPolling(name: string, eventName: string): UseMintPollingResult {
  const [status, setStatus] = React.useState<MintPollingStatus>('idle')
  const [error, setError] = React.useState<Error | null>(null)

  const { activeAddress } = useWallet()

  const { data: nfd } = useName({
    name,
    params: { view: 'full', poll: true },
    options: {
      enabled: status === 'polling',
      refetchInterval: 2000,
      retry: 3,
      onError: (error) => {
        setStatus('error')
        setError(error as Error)
      }
    }
  })

  React.useEffect(() => {
    if (status === 'polling') {
      if (nfd && nfd.owner === activeAddress) {
        setStatus('success')
        // Emit custom event
        window.dispatchEvent(new CustomEvent(eventName, { detail: { name: nfd.name } }))
      }
    }
  }, [activeAddress, eventName, nfd, status])

  const startPolling = () => {
    setStatus('polling')
  }

  return { startPolling, status, error }
}
