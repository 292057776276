import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { useWallet } from '@txnlab/use-wallet-react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import Link from 'next/link'
import * as React from 'react'
import toast from 'react-hot-toast'
import { TbCalendarX, TbClockDollar, TbClockPlus } from 'react-icons/tb'
import { useHydrated } from 'react-hydration-provider'
import { NfdRecord } from '@/api/api-client'
import { useNfdConstraints } from '@/api/hooks/contracts/useNfdConstraints'
import { useGetQuote } from '@/api/hooks/useGetQuote'
import { InfiniteSearchQueryResult } from '@/api/hooks/useInfiniteSearch'
import { useNameUpdate } from '@/api/hooks/useName'
import { usePostRenew } from '@/api/hooks/usePostRenew'
import Alert from '@/components/Alert'
import AlgoPrice from '@/components/AlgoPrice'
import { Divider } from '@/components/Divider'
import { Loading } from '@/components/LoadingSpinner'
import Modal, { ModalFooter } from '@/components/Modal'
import UsdPrice from '@/components/UsdPrice'
import { ZERO_ADDRESS } from '@/data/constants'
import { checkBalance } from '@/helpers/checkBalance'
import { formatPrice } from '@/helpers/utilities'
import useErrorToast from '@/hooks/useErrorToast'
import { useMintPolling } from '@/hooks/useMintPolling'
import usePush from '@/hooks/usePush'
import { useNocacheStore } from '@/store/index'
import { MintSlider } from './MintSlider'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

interface RenewDialogProps {
  nfd: NfdRecord
  disabled?: boolean
  children: React.ReactElement<{ onClick?: () => void; disabled?: boolean }>
  profileLink?: boolean
}

export default function RenewDialog({
  nfd,
  disabled,
  children,
  profileLink = false
}: RenewDialogProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [years, setYears] = React.useState(1)
  const [isPending, setIsPending] = React.useState(false)

  const { activeAddress } = useWallet()
  const queryClient = useQueryClient()
  const optimisticUpdate = useNameUpdate()
  const push = usePush()
  const handleError = useErrorToast()
  const toastId = React.useId()
  const hydrated = useHydrated()

  const addNameNocache = useNocacheStore((state) => state.addName)

  const { startPolling, status, error } = useMintPolling(nfd.name, 'nfdRenewed')
  const isDisabled = isPending || status === 'polling'
  const isExpired = dayjs(nfd.timeExpires).isBefore(dayjs())

  const constraintsQuery = useNfdConstraints()
  const constraints = constraintsQuery.data
  const maxYearsAllowed = constraints?.maxYearsAllowed || 0
  const auctionDuration = constraints?.expiredAuctionDuration || 0

  const now = dayjs()
  const expirationDate = dayjs(nfd.timeExpires)
  const auctionEndDate = expirationDate.add(auctionDuration, 'days')
  const isAuctionEnded = dayjs(auctionEndDate).isBefore(now)
  const newExpirationDate = isExpired ? now.add(years, 'years') : expirationDate.add(years, 'years')

  const quoteQuery = useGetQuote({
    name: nfd.name,
    params: {
      buyer: activeAddress || ZERO_ADDRESS
    },
    options: {
      enabled: isOpen && !isPending,
      refetchInterval: (data) => {
        if (data?.inAuction && !isAuctionEnded) {
          return 5000
        }
        return 1000 * 60 // 1 min
      }
    }
  })

  const isInAuction = quoteQuery.data?.inAuction || false
  const priceOneYear = quoteQuery.data?.price || 0
  const carryCost = quoteQuery.data?.carryCost || 0

  const adjustedMaxYears = React.useMemo(() => {
    if (isInAuction) {
      return 1
    }
    const remainingYears = dayjs(nfd.timeExpires).diff(dayjs(), 'year', true)
    return Math.floor(Math.max(0, Math.min(maxYearsAllowed - remainingYears, maxYearsAllowed)))
  }, [isInAuction, maxYearsAllowed, nfd.timeExpires])

  const totalPrice = React.useMemo(() => {
    if (isInAuction) {
      return priceOneYear
    }
    const pricePerYear = priceOneYear - carryCost
    return pricePerYear * years
  }, [carryCost, isInAuction, priceOneYear, years])

  const floorPriceQuery = useGetQuote({
    name: nfd.name,
    params: {
      buyer: nfd.owner || ZERO_ADDRESS
    },
    options: {
      enabled: isOpen && !isPending && isExpired && activeAddress !== nfd.owner,
      staleTime: 1000 * 60 // 1 min
    }
  })
  const floorPrice = floorPriceQuery.data?.price || 0

  const successToast = isExpired ? `Success! Purchased ${nfd.name}` : `Success! Renewed ${nfd.name}`

  const renewNfd = usePostRenew({
    onSuccess: (data, params) => {
      queryClient.invalidateQueries({ queryKey: ['name', params.name] })
      queryClient.invalidateQueries({ queryKey: ['expiration-banner', activeAddress] })
      queryClient.invalidateQueries({ queryKey: ['manage-owned', activeAddress] })
      queryClient.invalidateQueries({ queryKey: ['manage-expires-soon', activeAddress] })

      if (isInAuction) {
        toast.loading('Loading profile...', { id: toastId })
        startPolling()
      } else {
        const newNfd = {
          ...nfd,
          timeExpires: dayjs(nfd.timeExpires).add(years, 'years').toISOString()
        }
        optimisticUpdate(newNfd)
      }

      // Get the data from active address's `manage-expires-soon` queries
      const manageExpiresData = queryClient.getQueriesData<InfiniteData<InfiniteSearchQueryResult>>(
        ['manage-expires-soon', activeAddress]
      )

      // Remove the expired/expiring NFD from the cache
      manageExpiresData.forEach(([queryKey, queryData]) => {
        if (queryData) {
          const updatedPages = queryData.pages.map((page) => {
            const filteredNfds = page.data.nfds.filter((record) => record.name !== nfd.name)
            const removedCount = page.data.nfds.length - filteredNfds.length
            return {
              ...page,
              data: {
                ...page.data,
                nfds: filteredNfds,
                total: Math.max(0, page.data.total - removedCount)
              }
            }
          })

          const updatedQueryData = {
            ...queryData,
            pages: updatedPages
          }

          queryClient.setQueryData(queryKey, updatedQueryData)
        }
      })

      handleClose()
    },
    onError: (error) => {
      handleError(error)
    },
    onSettled: () => {
      setIsPending(false)
    },
    toasts: {
      success: successToast
    }
  })

  React.useEffect(() => {
    const handleNfdRenewPurchase = (event: CustomEvent) => {
      if (event.detail.name === nfd.name) {
        addNameNocache(nfd.name)
        const pathname = `/name/${nfd.name}`
        const params = {
          pathname,
          query: { purchased: true } // show confetti 🎉
        }
        push(params, pathname)
        toast.dismiss(toastId)
      }
    }

    window.addEventListener('nfdRenewPurchase', handleNfdRenewPurchase as EventListener)
    return () => {
      window.removeEventListener('nfdRenewPurchase', handleNfdRenewPurchase as EventListener)
    }
  }, [addNameNocache, nfd.name, push, toastId])

  React.useEffect(() => {
    if (status === 'error' && error) {
      handleError(error, { toastId })
    }
  }, [error, handleError, status, toastId])

  const handleRenew = async () => {
    try {
      if (!activeAddress) {
        throw new Error('Wallet not connected')
      }

      const balance = await checkBalance(activeAddress, totalPrice)

      if (!balance.hasSufficientBalance) {
        throw new Error(
          `Insufficient available balance. A minimum available balance of ${formatPrice(
            balance.balanceRequired as number,
            false,
            { maximumFractionDigits: 6 }
          )} ALGO is required to complete this transaction.`
        )
      }

      setIsPending(true)

      const payload = {
        buyer: activeAddress,
        name: nfd.name,
        years
      }

      renewNfd.mutate(payload)
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred'
      toast.error(errorMessage)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setTimeout(() => {
      setYears(1)
    }, 300)
  }

  const renderAuctionMessage = () => {
    if (isAuctionEnded) {
      return (
        <>
          <p className="text-gray-500 dark:text-gray-400 -mt-6 mb-8 sm:-mt-2 sm:leading-relaxed">
            The auction for this NFD has ended. It can be purchased for one year at the floor price.
          </p>
        </>
      )
    } else {
      return (
        <>
          <p className="text-gray-500 dark:text-gray-400 -mt-6 sm:-mt-2 sm:leading-relaxed">
            This NFD expired on{' '}
            <time
              dateTime={expirationDate.format('YYYY-MM-DD')}
              className="font-semibold text-gray-700 dark:text-gray-300"
            >
              {expirationDate.format('LL')}
            </time>{' '}
            and is currently in auction.{' '}
          </p>
          <p className="text-gray-500 dark:text-gray-400 mt-3 mb-8 sm:leading-relaxed">
            Its price will decrease incrementally until it reaches the floor price of{' '}
            <AlgoPrice
              price={floorPrice}
              className="font-semibold text-gray-700 dark:text-gray-300"
            />{' '}
            on{' '}
            <time
              dateTime={auctionEndDate.format('YYYY-MM-DD')}
              className="whitespace-nowrap font-semibold text-gray-700 dark:text-gray-300"
            >
              {auctionEndDate.format('LLL')}
            </time>
            .
          </p>
        </>
      )
    }
  }

  const renderModalBody = () => {
    if (quoteQuery.isLoading || constraintsQuery.isLoading) {
      return <Loading />
    }

    if (quoteQuery.error || constraintsQuery.error) {
      return (
        <div className="py-8 text-left">
          <Alert
            type="error"
            title="Error loading price data"
            message={
              quoteQuery.error?.message || constraintsQuery.error?.message || 'Unknown error'
            }
          />
        </div>
      )
    }

    return (
      <div className="mt-10 sm:mt-8">
        {isInAuction && renderAuctionMessage()}
        <div className="flex items-center justify-between flex-wrap">
          <p className="text-2xl/8 font-display font-medium xs:text-3xl/8 text-gray-700 dark:text-gray-300">
            {years} year{years > 1 ? 's' : ''}
          </p>
          <div className="text-2xl/8 font-display font-semibold xs:text-3xl/8 text-gray-900 dark:text-gray-100">
            <AlgoPrice price={totalPrice} />
          </div>
        </div>
        <div className="xs:mt-2">
          {isInAuction ? (
            <Divider soft className="my-4" />
          ) : (
            <MintSlider
              value={years}
              onChange={setYears}
              max={adjustedMaxYears}
              disabled={isPending}
            />
          )}
        </div>
        <div className="pb-8 text-left">
          <dl className="flex items-center justify-between flex-wrap gap-x-4 gap-y-2">
            <div className="flex gap-x-2">
              <dt className="flex-none">
                <span className="sr-only">Expires</span>
                <TbCalendarX
                  aria-hidden="true"
                  className="h-6 w-6 text-gray-400 dark:text-gray-500"
                />
              </dt>
              <dd className="text-sm leading-6 text-gray-500 dark:text-gray-400">
                <time dateTime={newExpirationDate.format('YYYY-MM-DD')}>
                  {newExpirationDate.format('LL')}
                </time>
              </dd>
            </div>
            <div className="flex gap-x-4">
              <dd className="text-right text-sm leading-6 text-gray-500 dark:text-gray-400">
                <UsdPrice
                  price={totalPrice}
                  className="text-sm leading-6 text-gray-500 dark:text-gray-400"
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }

  const renderTrigger = () => {
    const isOwner = activeAddress === nfd.owner

    if (isOwner || isExpired) {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          onClick: () => setIsOpen(true),
          disabled: isDisabled || disabled === true
        })
      }
    }

    return null
  }

  const modalTitleAction = isInAuction ? 'Buy' : 'Renew'
  const modalIcon = isInAuction ? TbClockDollar : TbClockPlus

  const renewButtonText = isPending ? 'Renewing...' : 'Renew NFD'
  const purchaseButtonText = isPending ? 'Buying...' : 'Buy Now'
  const modalButtonText = isInAuction ? purchaseButtonText : renewButtonText

  if (!hydrated) {
    return null
  }

  return (
    <>
      {renderTrigger()}

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={handleClose}
        title={
          <span
            className="break-words"
            style={
              {
                ['--name-length' as string]: `${nfd.name.length - 5}ch`,
                ['--suffix-length' as string]: '5ch',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                maxWidth: 'calc(100% - var(--suffix-length))'
              } as React.CSSProperties
            }
          >
            {modalTitleAction} {nfd.name.slice(0, -5)}
            <span className="inline-block whitespace-nowrap">.algo</span>
          </span>
        }
        icon={modalIcon}
        className="max-w-screen-sm"
        manualClose={isPending}
        showX={false}
      >
        {renderModalBody()}

        <ModalFooter className="mt-8 space-y-3 sm:space-y-0 sm:gap-3">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-brand-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 sm:w-auto sm:text-sm disabled:opacity-50 disabled:bg-brand-500 dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-brand-600"
            disabled={isDisabled}
            onClick={handleRenew}
          >
            {modalButtonText}
          </button>
          {profileLink && (
            <Link
              href={`/name/${nfd.name}`}
              className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 sm:w-auto sm:text-sm dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900"
              onClick={handleClose}
            >
              View Profile
            </Link>
          )}
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 sm:w-auto sm:text-sm dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900"
            onClick={handleClose}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}
