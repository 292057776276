import { useRouter } from 'next/router'
import { convertCentsToUsd, formatNumber } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

export function useSegmentInfo(nfd: NfdRecord) {
  const router = useRouter()

  const handleSegmentsInfoClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    router.push(`/name/${nfd.name}?view=segments`)
  }

  const isUnlocked = nfd?.properties?.internal?.segmentLocked === '0'

  const segmentPriceCents = parseInt(nfd?.properties?.internal?.segmentPriceUsd || '0')
  const usdMinCost = 4 // @todo: get this from the API

  const priceUsd = isUnlocked ? convertCentsToUsd(segmentPriceCents) : usdMinCost
  const priceUsdStr = `$ ${formatNumber(priceUsd, { minimumFractionDigits: 2 })}`

  const segmentCount = parseInt(nfd?.properties?.internal?.segmentCount || '0')

  const showSegmentInfo = isUnlocked

  return {
    showSegmentInfo,
    handleSegmentsInfoClick,
    isUnlocked,
    priceUsd,
    priceUsdStr,
    segmentCount
  }
}
