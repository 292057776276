export default function SegmentsIcon({ className = '' }) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g>
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          d="M7,5.3H5.4C4.4,5.3,3.7,6,3.7,7v1.7c0,0.9,0.8,1.7,1.7,1.7H7c0.9,0,1.7-0.8,1.7-1.7V7C8.7,6,8,5.3,7,5.3z"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          x1="11.4"
          y1="7.8"
          x2="18.2"
          y2="7.8"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          d="M10,13.7H8.4c-0.9,0-1.7,0.8-1.7,1.7V17c0,0.9,0.8,1.7,1.7,1.7H10c0.9,0,1.7-0.8,1.7-1.7v-1.7 C11.7,14.4,11,13.7,10,13.7z"
        />
        <line
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          x1="14.4"
          y1="16.2"
          x2="20.3"
          y2="16.2"
        />
      </g>
    </svg>
  )
}
