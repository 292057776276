import dynamic from 'next/dynamic'
import { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AiFillBank } from 'react-icons/ai'
import UserThumbnail from 'components/UserThumbnail'
import TokenImage from 'components/DetailView/Assets/AssetPreview/TokenImage'
import AssetMedia from 'components/AssetMedia'
import { formatAmount } from 'helpers/format'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { isAssetNft } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'
import type { Asset } from 'types/assets'

const Modal = dynamic(() => import('components/Modal'))

interface VaultModalProps {
  nfd: NfdRecord
  assets: Array<Asset>
  className?: string
  children: React.ReactNode
}

export default function VaultModal({ nfd, assets, className = '', children }: VaultModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const vaultAddress = nfd.nfdAccount

  const getVaultAmount = (asset: Asset) => {
    const vaultAmount = asset.amounts.find((amount) => amount.account === vaultAddress)?.amount

    if (!vaultAmount) return null

    return formatAmount(vaultAmount, { decimals: asset.decimals, maxLength: 10 })
  }

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(true)
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(false)
  }

  const renderPreview = (asset: Asset) => {
    if (!isAssetNft(asset.totalCreated, asset.decimals) && !asset.imageUrl) {
      return <TokenImage />
    }

    return (
      <AssetMedia
        src={asset.imageUrl}
        alt={asset.id.toString()}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="48px"
        fill
        options={{ showVideoIcon: false, stateBgColor: 'bg-gray-500/5 dark:bg-gray-500/5' }}
        videoJsOptions={{
          preload: isMobile ? 'auto' : 'metadata',
          controls: isMobile,
          fluid: true,
          fill: true
        }}
      />
    )
  }

  return (
    <>
      <button type="button" className={className} onClick={handleOpen}>
        {children}
      </button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Vault Assets"
        icon={AiFillBank}
        initialFocus={closeButtonRef}
        showX={false}
        centered
        className="max-w-sm"
      >
        <div className="mt-6 flex justify-center">
          <UserThumbnail nfd={nfd} textSize="text-base" />
        </div>

        <div className="mt-6 space-y-2.5 max-h-48 overflow-y-auto px-6 -mx-6">
          {assets.map((asset) => (
            <div key={asset.id} className="flex items-center">
              <div className="relative flex-shrink-0 w-12 h-12 rounded-full overflow-hidden bg-gray-500/10">
                {renderPreview(asset)}
              </div>
              <div className="ml-3 flex-1 flex items-center justify-between min-w-0 text-sm font-medium text-gray-900 dark:text-gray-200">
                <p className="truncate font-medium">{asset.name}</p>
                <span className="ml-4 font-mono">{getVaultAmount(asset)}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8">
          <button
            ref={closeButtonRef}
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-gray-100"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}
