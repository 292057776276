/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  HiOutlineCheckCircle,
  HiOutlineExclamation,
  HiOutlineXCircle,
  HiOutlineInformationCircle,
  HiX
} from 'react-icons/hi'
import { clsxMerge } from 'src/helpers/utilities'

export const ModalFooter = ({
  className = '',
  children
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div className={clsxMerge('mt-5 sm:mt-4 sm:flex sm:flex-row-reverse', className)}>
      {children}
    </div>
  )
}

interface Style {
  icon: (props: any) => JSX.Element
  color: string
  bg: string
}

interface StyleMap {
  [key: string]: Style
}

const styles: StyleMap = {
  success: {
    icon: (props: any) => <HiOutlineCheckCircle {...props} />,
    color: 'text-green-400 dark:text-green-300',
    bg: 'bg-green-100 dark:bg-gray-300/10'
  },
  error: {
    icon: (props: any) => <HiOutlineXCircle {...props} />,
    color: 'text-red-400 dark:text-red-300',
    bg: 'bg-red-100 dark:bg-gray-300/10'
  },
  info: {
    icon: (props: any) => <HiOutlineInformationCircle {...props} />,
    color: 'text-blue-400 dark:text-blue-300',
    bg: 'bg-blue-100 dark:bg-gray-300/10'
  },
  warning: {
    icon: (props: any) => <HiOutlineExclamation {...props} />,
    color: 'text-amber-400 dark:text-amber-300',
    bg: 'bg-amber-100 dark:bg-gray-300/10'
  }
}

interface ModalInterface {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  title?: string | React.ReactNode
  children: React.ReactNode
  showX?: boolean
  manualClose?: boolean
  onClose?: () => void
  initialFocus?: React.RefObject<HTMLElement | null>
  centered?: boolean
  className?: string
}

// style can be set, or icon (with optional color) can be set, but not both
type ModalProps = (
  | {
      style: 'success' | 'error' | 'info' | 'warning' | null
      icon?: never
      iconColor?: never
      bgColor?: never
    }
  | {
      style?: never
      icon: (props: any) => JSX.Element
      iconColor?: string
      bgColor?: string
    }
  | {
      style?: never
      icon?: never
      iconColor?: never
      bgColor?: never
    }
) &
  ModalInterface

const Modal = (props: ModalProps) => {
  const {
    isOpen = false,
    setIsOpen,
    title,
    style,
    icon,
    iconColor = 'text-brand-400 dark:text-brand-300',
    bgColor = 'bg-brand-100 dark:bg-gray-300/10',
    showX = true,
    manualClose = false,
    onClose,
    initialFocus,
    centered = false,
    className = '',
    children
  } = props

  const Icon = ({ className }: { className: string }) =>
    style ? styles[style].icon({ className }) : icon ? icon({ className }) : null

  const color = style ? styles[style].color : iconColor
  const bg = style ? styles[style].bg : bgColor

  const handleClose = () => {
    if (onClose) {
      return onClose()
    }

    if (!manualClose) {
      setIsOpen(false)
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[60] inset-0 overflow-y-auto"
        onClose={handleClose}
        initialFocus={initialFocus}
      >
        <div className="flex items-center justify-center min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity dark:bg-black/75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsxMerge(
                'ease-in-out delay-150 inline-block align-middle bg-white rounded-2xl mx-4 my-8 w-full text-left overflow-hidden shadow-xl transform transition-all max-w-md dark:bg-gray-900',
                className
              )}
            >
              {showX && (
                <div
                  className={clsxMerge(
                    !!icon ? 'pt-5 pr-5' : 'pt-4 pr-4',
                    'hidden sm:block absolute top-0 right-0'
                  )}
                >
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 dark:bg-transparent dark:text-gray-600 dark:hover:text-gray-500 dark:ring-offset-gray-900 dark:ring-gray-300"
                    onClick={() => {
                      if (onClose) onClose()
                      setIsOpen(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <HiX className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}

              <div className="p-6">
                <div className={clsxMerge(centered ? '' : 'sm:flex sm:items-start')}>
                  {(style !== null || !!icon) && (
                    <div
                      className={clsxMerge(
                        centered ? '' : 'sm:-mt-1 sm:mx-0 sm:h-10 sm:w-10',
                        `mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${bg}`
                      )}
                    >
                      <Icon className={`h-6 w-6 ${color}`} aria-hidden="true" />
                    </div>
                  )}
                  <div
                    className={clsxMerge(
                      !!Icon ? 'mt-3' : '',
                      centered ? '' : 'sm:mt-0 sm:ml-4 sm:text-left sm:flex-1',
                      'text-center'
                    )}
                  >
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className={clsxMerge(
                          !!icon ? 'sm:pt-1' : '',
                          'text-lg leading-6 font-medium text-gray-900 dark:text-gray-100'
                        )}
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    <div className="mt-2">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.Footer = ModalFooter

export default Modal
