import { getAccountInfo } from './node'

/**
 * @description Check if the active account's balance meets the MBR for a given transaction
 */
export const checkBalance = async (
  address: string | undefined,
  transactionCost: number,
  isPurchase = true
) => {
  if (!address) {
    throw new Error('No address provided.')
  }
  const result = await getAccountInfo(address, true)

  const minBalance = result['min-balance']
  const balance = result.amount

  if (balance === undefined || minBalance === undefined) {
    throw new Error('Unable to get balance information.')
  }

  const balanceRequired = transactionCost + minBalance + (isPurchase ? 100000 : 0)
  const hasSufficientBalance = balance >= balanceRequired

  return {
    hasSufficientBalance,
    balanceRequired
  }
}
