import { isMobile } from 'react-device-detect'
import { AiFillBank } from 'react-icons/ai'
import { FaChevronRight } from 'react-icons/fa'
import VaultModal from './VaultModal'
import useVaultAssets from 'api/hooks/useVaultAssets'
import type { NfdRecord } from 'api/api-client'

interface VaultInfoProps {
  nfd: NfdRecord
  view: 'grid' | 'list' | 'listMobile'
  enableQuery: boolean
}

export default function VaultInfo({ nfd, view, enableQuery }: VaultInfoProps) {
  const { showVaultInfo, assets, assetsCount, assetTypeCount } = useVaultAssets(nfd, {
    enableQuery: enableQuery
  })

  const getListViewString = () => {
    const arr: string[] = []

    if (Number(assetTypeCount?.nfts) > 0) {
      arr.push(`${assetTypeCount?.nfts} NFT${Number(assetTypeCount?.nfts) > 1 ? 's' : ''}`)
    }

    if (Number(assetTypeCount?.tokens) > 0) {
      arr.push(`${assetTypeCount?.tokens} Token${Number(assetTypeCount?.tokens) > 1 ? 's' : ''}`)
    }

    return arr.join(', ')
  }

  if (!showVaultInfo) {
    return null
  }

  if (view === 'grid') {
    if (showVaultInfo && Number(assetsCount) > 0) {
      if (isMobile) {
        return (
          <VaultModal
            nfd={nfd}
            assets={assets || []}
            className="absolute top-0 left-0 p-3 z-20 outline-brand-500"
          >
            <span className="inline-flex items-center bg-gray-800 rounded-md p-1">
              <AiFillBank className="h-5 w-5 text-white" />
            </span>
          </VaultModal>
        )
      }

      return (
        <>
          <VaultModal
            nfd={nfd}
            assets={assets || []}
            className="absolute top-0 left-0 m-3 peer z-20 inline-flex items-center bg-gray-800 rounded-md p-1 hover:bg-brand-500 transition-colors outline-gray-900"
          >
            <AiFillBank className="h-5 w-5 text-white" />
          </VaultModal>

          <span className="absolute -left-2 right-1/3 inset-y-0 pointer-events-none bg-gradient-to-r from-gray-900 to-transparent flex text-white opacity-0 peer-hover:translate-x-0 peer-hover:opacity-100 transition-all z-10 ease-in p-4 shadow-sm rotate-12 scale-125" />

          <span className="absolute inset-x-0 top-0 px-4 pt-8 opacity-0 peer-hover:opacity-100 transition-opacity z-[19]">
            <span className="font-medium text-sm text-white mt-8 ml-3 space-y-1.5">
              <span className="flex items-center">
                <ul className="space-y-1">
                  {Number(assetTypeCount?.nfts) > 0 && (
                    <li>
                      {assetTypeCount?.nfts} {`NFT${Number(assetTypeCount?.nfts) > 1 ? 's' : ''}`}
                    </li>
                  )}
                  {Number(assetTypeCount?.tokens) > 0 && (
                    <li>
                      {assetTypeCount?.tokens}{' '}
                      {`Token${Number(assetTypeCount?.tokens) > 1 ? 's' : ''}`}
                    </li>
                  )}
                </ul>
              </span>
            </span>
          </span>

          <span className="absolute top-0 left-12 pointer-events-none my-3 z-[19] opacity-0 peer-hover:opacity-100 transition-opacity">
            <span className="text-xs font-bold text-white">Vault Assets</span>
          </span>

          <span className="absolute top-0 left-32 pointer-events-none my-3 h-6 flex items-center z-[19] opacity-0 -translate-x-1.5 peer-hover:translate-x-0 peer-hover:opacity-100 transition-all">
            <FaChevronRight className="w-3 h-3 mt-0.5 text-white/80" />
          </span>
        </>
      )
    }

    return null
  }

  if (view === 'list') {
    if (showVaultInfo) {
      return (
        <VaultModal
          nfd={nfd}
          assets={assets || []}
          className="flex items-center group/vaultinfo-list m-0 py-1 px-3 text-left rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:ring-offset-gray-900"
        >
          <span className="flex items-center justify-center bg-gray-700 rounded-md p-1 mr-3.5 group-hover/vaultinfo-list:bg-brand-500 transition-colors dark:bg-gray-750">
            <AiFillBank className="h-6 w-6 text-white" />
          </span>
          <span className="flex flex-col justify-center m-0">
            <span className="inline-flex items-center text-xs font-bold text-gray-900 dark:text-gray-200">
              Vault Assets
              <FaChevronRight className="w-3 h-3 ml-2 opacity-0 -translate-x-1.5 group-hover/vaultinfo-list:opacity-100 group-hover/vaultinfo-list:translate-x-0 transition-all" />
            </span>
            <span className="-ml-0.5 flex flex-wrap items-center font-medium text-sm text-gray-700 dark:text-gray-400">
              <span className="flex items-center whitespace-nowrap mr-4">
                {getListViewString()}
              </span>
            </span>
          </span>
        </VaultModal>
      )
    }

    return null
  }

  if (showVaultInfo) {
    return (
      <VaultModal
        nfd={nfd}
        assets={assets || []}
        className="bg-gray-700 rounded-md p-1 hover:bg-brand-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:ring-offset-gray-900"
      >
        <AiFillBank className="h-5 w-5 text-white" />
      </VaultModal>
    )
  }

  return null
}
