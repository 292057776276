import Slider from '@mui/material/Slider'
import { createTheme, styled, ThemeProvider } from '@mui/material/styles'
import { useTheme } from 'next-themes'

const muiTheme = createTheme()

// CSS customizations for the MUI Slider component
const LightSlider = styled(Slider)({
  color: '#eee',
  '& .MuiSlider-rail': {
    borderColor: '#fff',
    backgroundColor: '#e5e7eb',
    height: 8
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    '&:before': {
      boxShadow:
        '0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13), 0 0 0 1px rgba(0, 0, 0, 0.02)'
    },
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(255, 96, 46, 0.16)'
    },
    '&.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgba(255, 96, 46, 0.16)'
    },
    '&.Mui-active': {
      boxShadow: '0px 0px 0px 12px rgba(255, 96, 46, 0.16)'
    },
    // Expanded 44px touch area
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '44px',
      height: '44px',
      borderRadius: '50%',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      // Media query to hide on devices with fine pointers
      '@media (pointer: fine)': {
        display: 'none'
      }
    }
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: '#111827',
    borderRadius: '4px',
    fontWeight: 500
  },
  '& .MuiSlider-track': {
    backgroundImage: 'linear-gradient(to right, #FF602E, #fbbf24)',
    height: 8
  }
})

const DarkSlider = styled(Slider)({
  color: '#1e293b',
  '& .MuiSlider-rail': {
    borderColor: '#111827',
    backgroundColor: '#374151',
    height: 8
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    '&:before': {
      boxShadow:
        '0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13), 0 0 0 1px rgba(0, 0, 0, 0.02)'
    },
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16)'
    },
    '&.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16)'
    },
    '&.Mui-active': {
      boxShadow: '0px 0px 0px 12px rgba(25, 118, 210, 0.16)'
    },
    // Expanded 44px touch area
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '44px',
      height: '44px',
      borderRadius: '50%',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      // Media query to hide on devices with fine pointers
      '@media (pointer: fine)': {
        display: 'none'
      }
    }
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: '#1e293b',
    color: '#e2e8f0',
    borderRadius: '4px',
    fontWeight: 500
  },
  '& .MuiSlider-track': {
    backgroundImage: 'linear-gradient(to right, #FF602E, #fbbf24)',
    height: 8
  }
})

interface MintSliderProps {
  value: number
  onChange: (value: number) => void
  step?: number
  min?: number
  max: number
  disabled?: boolean
}

export function MintSlider({
  value,
  onChange,
  step = 1,
  min = 1,
  max,
  disabled = false
}: MintSliderProps) {
  const { theme } = useTheme()

  const CustomSlider = theme === 'light' ? LightSlider : DarkSlider

  const handleSliderChange = (event: Event, newSliderValue: number | number[]) => {
    onChange(newSliderValue as number)
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <CustomSlider
        aria-label="Mint NFD duration"
        aria-valuetext={`${value} years`}
        value={value}
        onChange={handleSliderChange}
        step={step}
        min={min}
        max={max}
        disabled={disabled}
      />
    </ThemeProvider>
  )
}
