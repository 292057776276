import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import Link from 'next/link'
import * as React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { HiLockOpen } from 'react-icons/hi'
import { IoMdPricetag } from 'react-icons/io'
import { RiBookmark3Fill } from 'react-icons/ri'
import { isMobile } from 'react-device-detect'
import { useGetQuote } from 'api/hooks/useGetQuote'
import { useNfdConstraints } from 'api/hooks/contracts/useNfdConstraints'
import AssetMedia from 'components/AssetMedia'
import { ResultTag, ResultTags } from 'components/ResultTag'
import PriceTag from 'components/PriceTag'
import VerifiedBadge from 'components/VerifiedBadge'
import Tooltip from 'components/Tooltip'
import UsdPrice from 'components/UsdPrice'
// import AuctionPrice from 'components/AuctionPrice'
// import AuctionTag from 'components/AuctionTag'
import TextAvatar from 'components/TextAvatar'
import PriceTagUsd from 'components/PriceTagUsd'
import RenewDialog from 'components/mint/RenewDialog'
import SegmentsIcon from 'components/icons/Segments'
import { ZERO_ADDRESS } from '@/data/constants'
import SegmentModalButton from './SegmentModalButton'
import VaultInfo from './VaultInfo'
import { useSegmentInfo } from './NfdResult.hooks'
import { getAvatarURL, isAvatarVerified, showTextAvatar } from 'helpers/avatar'
import galleryImageLoader from 'helpers/galleryImageLoader'
import { clsxMerge } from 'helpers/utilities'
import { getNfdVersion, meetsMinimumVersion } from 'helpers/versions'
import getTags from 'helpers/getTags'
import type { NfdRecord } from 'api/api-client'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

interface ResultProps {
  view: 'list' | 'grid'
  nfd: NfdRecord
  activeWallet: string | undefined
  // auction?: NFDAuctionAndPrice | undefined
  context?: 'manage' | 'browse'
}

export default function NfdResult({
  view,
  nfd,
  activeWallet = '',
  // auction,
  context = 'browse'
}: ResultProps) {
  const {
    showSegmentInfo,
    handleSegmentsInfoClick,
    isUnlocked,
    priceUsd,
    priceUsdStr,
    segmentCount
  } = useSegmentInfo(nfd)

  const isList = view === 'list'

  const isOwnedByUser = nfd.owner === activeWallet
  const isTransfer = nfd.state === 'forSale' && nfd.sellAmount === 1000000
  const isForSale = nfd.state === 'forSale' && !isTransfer
  const isReservedForUser = nfd.reservedFor === activeWallet
  const isReservedForSomeoneElse = !!nfd.reservedFor && nfd.reservedFor !== activeWallet
  const isExpired = dayjs(nfd.timeExpires).isBefore(dayjs())
  const expiresSoon = dayjs(nfd.timeExpires).isBefore(dayjs().add(30, 'days'))
  const isExpiredState = nfd.state === 'expired'
  // const isInAuction = !!auction

  const isBrowse = context === 'browse'
  const hideReservedPrice = isBrowse && isReservedForSomeoneElse

  const constraintsQuery = useNfdConstraints()
  const constraints = constraintsQuery.data
  const auctionDuration = constraints?.expiredAuctionDuration || 0
  const now = dayjs()
  const expirationDate = dayjs(nfd.timeExpires)
  const auctionEndDate = expirationDate.add(auctionDuration, 'days')
  const isAuctionEnded = auctionEndDate.isBefore(now)

  const quoteQuery = useGetQuote({
    name: nfd.name,
    params: {
      buyer: activeWallet || ZERO_ADDRESS
    },
    options: {
      enabled: isExpiredState,
      staleTime: 1000 * 60, // 1 min
      refetchOnWindowFocus: false
    }
  })

  const isInAuction = quoteQuery.data?.inAuction || false
  const expiredPrice = quoteQuery.data?.price || 0
  const price = isExpired ? expiredPrice : nfd.sellAmount

  const queryClient = useQueryClient()
  const throttleTimeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  const disableHoverCallback = isBrowse || !isExpiredState || !isInAuction || isAuctionEnded

  const handleHover = React.useCallback(() => {
    if (!activeWallet || disableHoverCallback) return

    if (!throttleTimeoutRef.current) {
      queryClient.invalidateQueries({
        queryKey: ['mint-quote', { name: nfd.name, buyer: activeWallet }]
      })

      throttleTimeoutRef.current = setTimeout(() => {
        throttleTimeoutRef.current = null
      }, 5000)
    }
  }, [activeWallet, disableHoverCallback, nfd.name, queryClient])

  React.useEffect(() => {
    return () => {
      if (throttleTimeoutRef.current) {
        clearTimeout(throttleTimeoutRef.current)
      }
    }
  }, [])

  const renderTextBadge = () => {
    const textBadgeClassName = clsxMerge(
      isList ? 'origin-left mr-1.5' : 'origin-top-right',
      'scale-90 shadow-sm'
    )

    // if (isInAuction) {
    //   return <AuctionTag auction={auction} />
    // }

    if (isInAuction) {
      if (isAuctionEnded) {
        return (
          <ResultTag label="Buy Now" brand className={clsxMerge(textBadgeClassName, 'font-bold')} />
        )
      }
      return (
        <ResultTag
          label={
            <span className="flex items-center">
              <svg
                className="-ml-0.5 mr-1.5 h-2 w-2 text-white animate-blink"
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx={4} cy={4} r={3} />
              </svg>
              Live <span className="hidden md:inline">&nbsp;Auction</span>
            </span>
          }
          brand
          className={clsxMerge(textBadgeClassName, 'font-bold')}
        />
      )
    }

    if (isExpiredState) {
      return (
        <ResultTag label="Renew Now" brand className={clsxMerge(textBadgeClassName, 'font-bold')} />
      )
    }

    if (isReservedForUser) {
      return <ResultTag label="Claim Now" brand className={textBadgeClassName} />
    }

    if (isOwnedByUser && isTransfer) {
      return <ResultTag label="Manage Transfer" brand className={textBadgeClassName} />
    }

    if (isOwnedByUser && isForSale) {
      return <ResultTag label="Manage Sale" brand className={textBadgeClassName} />
    }

    if (isReservedForSomeoneElse) {
      return (
        <ResultTag
          label="Reserved"
          color="slate"
          className={clsxMerge(textBadgeClassName, 'font-bold')}
        />
      )
    }

    if (isForSale) {
      return <ResultTag label="Buy Now" brand className={textBadgeClassName} />
    }

    if (isUnlocked) {
      return (
        <ResultTag
          label="Unlocked"
          icon={HiLockOpen}
          iconClassName="dark:text-gray-200"
          color="gray"
          className="shadow-sm backdrop-blur-sm mr-1.5"
        />
      )
    }

    return <span />
  }

  const renderGridSegmentsInfo = () => {
    if (showSegmentInfo) {
      if (isMobile) {
        return (
          <SegmentModalButton
            nfd={nfd}
            segmentCount={segmentCount}
            segmentPriceUsd={priceUsdStr}
            className="absolute top-0 left-0 p-3 z-20 outline-brand-500"
          >
            <span className="inline-flex items-center bg-gray-800 rounded-md p-1">
              <SegmentsIcon className="h-5 w-5 text-white" />
            </span>
          </SegmentModalButton>
        )
      }

      return (
        <>
          <button
            type="button"
            className="absolute top-0 left-0 m-3 peer z-20 inline-flex items-center bg-gray-800 rounded-md p-1 hover:bg-brand-500 transition-colors outline-gray-900"
            onClick={handleSegmentsInfoClick}
          >
            <SegmentsIcon className="h-5 w-5 text-white" />
          </button>

          <span className="absolute -left-2 right-1/3 inset-y-0 pointer-events-none bg-gradient-to-r from-gray-900 to-transparent flex text-white opacity-0 peer-hover:translate-x-0 peer-hover:opacity-100 transition-all z-10 ease-in p-4 shadow-sm rotate-12 scale-125" />

          <span className="absolute inset-x-0 top-0 px-4 pt-8 opacity-0 peer-hover:opacity-100 transition-opacity z-[19]">
            <span className="font-medium text-sm text-white mt-8 ml-3 space-y-1.5">
              <span className="flex items-center">
                <HiLockOpen className="h-4 w-4 ml-0.5 mr-1.5 text-brand-500" />
                Unlocked
              </span>
              <span className="flex items-center">
                <IoMdPricetag className="h-4 w-4 ml-0.5 mr-1.5 text-brand-500" />
                {priceUsdStr}
              </span>
              <span className="flex items-center">
                <SegmentsIcon className="h-4 w-4 ml-0.5 mr-1.5 text-brand-500" />
                {segmentCount} total
              </span>
            </span>
          </span>

          <span className="absolute top-0 left-12 pointer-events-none my-3 z-[19] opacity-0 peer-hover:opacity-100 transition-opacity">
            <span className="text-xs font-bold text-white">Segmenting</span>
          </span>

          <span className="absolute top-0 left-32 pointer-events-none my-3 h-6 flex items-center z-[19] opacity-0 -translate-x-1.5 peer-hover:translate-x-0 peer-hover:opacity-100 transition-all">
            <FaChevronRight className="w-3 h-3 mt-0.5 text-white/80" />
          </span>
        </>
      )
    }

    return null
  }

  const renderListSegmentsInfo = () => {
    if (showSegmentInfo) {
      return (
        <button
          type="button"
          className="flex items-center group/segmentslist m-0 py-1 px-3 text-left rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          onClick={handleSegmentsInfoClick}
        >
          <span className="flex items-center justify-center bg-gray-700 rounded-md p-1 mr-3.5 group-hover/segmentslist:bg-brand-500 transition-colors dark:bg-gray-750">
            <SegmentsIcon className="h-6 w-6 text-white" />
          </span>
          <span className="flex flex-col justify-center m-0">
            <span className="inline-flex items-center text-xs font-bold text-gray-900 dark:text-gray-200">
              Segmenting
              <FaChevronRight className="w-3 h-3 ml-2 opacity-0 -translate-x-1.5 group-hover/segmentslist:opacity-100 group-hover/segmentslist:translate-x-0 transition-all" />
            </span>
            <span className="-ml-0.5 flex flex-wrap items-center font-medium text-sm text-gray-700 dark:text-gray-400">
              <span className="flex items-center whitespace-nowrap mr-4">
                <HiLockOpen className="h-4 w-4 mr-1.5 text-brand-500 dark:text-gray-600" />
                Unlocked
              </span>
              {isUnlocked && (
                <span className="flex items-center whitespace-nowrap mr-4">
                  <IoMdPricetag className="h-4 w-4 mr-1.5 text-brand-500 dark:text-gray-600" />
                  {priceUsdStr}
                </span>
              )}
              <span className="flex items-center whitespace-nowrap mr-4">
                <SegmentsIcon className="h-4 w-4 mr-1.5 text-brand-500 dark:text-gray-600" />
                {segmentCount} total
              </span>
            </span>
          </span>
        </button>
      )
    }

    return null
  }

  const renderListSegmentsInfoMobile = () => {
    if (showSegmentInfo) {
      if (isMobile) {
        return (
          <SegmentModalButton
            nfd={nfd}
            segmentCount={segmentCount}
            segmentPriceUsd={priceUsdStr}
            className="bg-gray-700 rounded-md p-1 hover:bg-brand-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          >
            <SegmentsIcon className="h-5 w-5 text-white" />
          </SegmentModalButton>
        )
      }

      return (
        <button
          type="button"
          className="bg-gray-700 rounded-md p-1 hover:bg-brand-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          onClick={handleSegmentsInfoClick}
        >
          <SegmentsIcon className="h-5 w-5 text-white" />
        </button>
      )
    }

    return null
  }

  const renderVerifiedBadge = () => {
    const isVerified = isAvatarVerified(nfd)

    if (isVerified) {
      return (
        <div
          className={clsxMerge(
            'absolute bottom-0 right-0',
            isList ? '-mr-1 -mb-1' : '-mr-12 -mb-12'
          )}
        >
          <Tooltip
            text="Verified avatar"
            direction={isList ? 'right' : 'left'}
            className={clsxMerge(isList ? '' : '-translate-y-7')}
          >
            <div>
              <VerifiedBadge circle={isList} />
            </div>
          </Tooltip>
        </div>
      )
    }

    return null
  }

  const renderPrice = () => {
    if (isUnlocked) {
      return (
        <Tooltip as="span" text="Segment price" wrapperClassName="mt-1" className="translate-y-2">
          <button
            type="button"
            className="group/segmentprice rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            onClick={handleSegmentsInfoClick}
          >
            <PriceTagUsd
              price={priceUsd}
              className={clsxMerge(
                isList ? '2xl:text-base' : '',
                'group-hover/segmentprice:bg-gray-200 dark:group-hover/segmentprice:bg-gray-100/20'
              )}
            />
          </button>
        </Tooltip>
      )
    }

    if (!price || hideReservedPrice) {
      return null
    }

    return (
      <span className="flex items-center flex-wrap space-x-1 mt-1">
        <span>
          {/* {isInAuction ? (
            <AuctionPrice
              price={price}
              auction={auction}
              className={clsxMerge(isList ? '2xl:text-base' : '')}
            />
          ) : (
            <PriceTag
              price={price}
              className={clsxMerge(isList ? '2xl:text-base' : '')}
            />
          )} */}
          <PriceTag
            price={price}
            className={clsxMerge(
              isList ? '2xl:text-base' : '',
              isInAuction && !isAuctionEnded && 'dark:text-white'
            )}
          />
        </span>
        <UsdPrice price={price} className="my-1" />
      </span>
    )
  }

  const renderAvatar = () => {
    return showTextAvatar(nfd) ? (
      <TextAvatar
        nfd={nfd}
        className="absolute inset-0 object-cover w-full h-full"
        imgClassName={clsxMerge(isExpiredState && 'opacity-0')}
      />
    ) : (
      <AssetMedia
        src={getAvatarURL(nfd)}
        alt={nfd.name}
        className="object-cover w-full h-full"
        loader={galleryImageLoader}
        sizes="(max-width: 640px) 300px, 360px"
        fill
        options={{ stateBgColor: 'transparent' }}
      />
    )
  }

  const renderContent = () => {
    return (
      <>
        {/* Grid view */}
        <div className={clsxMerge(isList ? 'hidden' : '')}>
          <div className="bg-gray-700 rounded-lg overflow-hidden group-hover:scale-[1.02] transition group-hover:shadow-md origin-bottom dark:bg-white/5 dark:group-hover:ring-inset dark:group-hover:ring-gray-900 dark:group-hover:ring-1">
            <div className="relative aspect-square">
              {renderAvatar()}
              {isForSale ? (
                <VaultInfo nfd={nfd} view="grid" enableQuery={!isList} />
              ) : (
                renderGridSegmentsInfo()
              )}
              <span
                className={clsxMerge(
                  isList
                    ? ''
                    : 'absolute top-0 right-0 p-3 peer-hover:translate-x-full transition-transform'
                )}
              >
                {renderTextBadge()}
              </span>
              {renderVerifiedBadge()}
            </div>
          </div>
          <div className="flex-1 flex items-start justify-between mt-2 min-w-0 z-20">
            <div className="min-w-0">
              <h3 className="text-sm font-semibold text-gray-900 truncate px-1.5 overflow-visible dark:text-gray-300/80">
                {nfd.name}
              </h3>
              <div
                className={clsxMerge('pt-1 sm:min-h-[30px]', {
                  hidden: !price || hideReservedPrice
                })}
              >
                {renderPrice()}
              </div>
              {meetsMinimumVersion(nfd, '3') ? (
                <Tooltip
                  as="span"
                  text={dayjs(nfd.timeExpires).format('lll')}
                  className="translate-y-3"
                >
                  <p className="mt-2 text-sm px-1.5 text-gray-700 dark:text-gray-400">
                    <ResultTag
                      label={isExpired ? 'Expired' : `Expires ${dayjs(nfd.timeExpires).fromNow()}`}
                      color={expiresSoon ? 'red' : 'gray'}
                      className={clsxMerge(expiresSoon ? 'font-bold' : '')}
                    />
                  </p>
                </Tooltip>
              ) : (
                <ResultTag label={`v${getNfdVersion(nfd)}`} color="gray" className="mt-1.5" />
              )}
            </div>
            {nfd.category === 'curated' && (
              <Tooltip as="span" text="Curated">
                <RiBookmark3Fill className="inline-flex h-4 w-4 mx-1.5 text-amber-500" />
              </Tooltip>
            )}
          </div>
        </div>

        {/* List view */}
        <div className={clsxMerge(isList ? '' : 'hidden')}>
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="min-w-0 flex-1 flex items-center space-x-4">
              <div className="flex-shrink-0 relative h-12 w-12">
                <div className="bg-black/5 relative h-12 w-12 rounded-full overflow-hidden dark:bg-white/5">
                  <AssetMedia
                    src={getAvatarURL(nfd)}
                    alt={nfd.name}
                    className={clsxMerge(
                      'object-cover w-full h-full',
                      isExpiredState && 'grayscale opacity-[.33]'
                    )}
                    loader={galleryImageLoader}
                    sizes="48px"
                    fill
                    options={{ stateBgColor: 'transparent' }}
                  />
                </div>
                {renderVerifiedBadge()}
              </div>
              <div
                className={clsxMerge(
                  !price || hideReservedPrice ? 'sm:grid' : 'grid',
                  'min-w-0 flex-1 gap-4 grid-cols-2 xl:grid-cols-[2fr,1fr,24rem] 2xl:grid-cols-[1.5fr,1fr,0.75fr,24rem] 4xl:grid-cols-4'
                )}
              >
                <div className="flex items-center">
                  <p className="text-sm md:text-base 2xl:text-lg 2xl:leading-tight font-medium truncate lg:overflow-visible dark:text-gray-300/80">
                    <span className="mr-3">{nfd.name}</span>
                    {nfd.category === 'curated' && (
                      <Tooltip
                        as="span"
                        text="Curated"
                        className="translate-y-1.5"
                        wrapperClassName="-ml-1.5 mr-3"
                      >
                        <span className="relative -top-0.5">
                          <RiBookmark3Fill className="inline-flex h-4 w-4 text-amber-500" />
                        </span>
                      </Tooltip>
                    )}
                    <wbr />
                    {renderTextBadge()}
                    {meetsMinimumVersion(nfd, '3') ? (
                      <Tooltip
                        as="span"
                        text={dayjs(nfd.timeExpires).format('lll')}
                        className="translate-y-3"
                      >
                        <span className="text-sm pr-1.5 text-gray-700 dark:text-gray-400">
                          <ResultTag
                            label={
                              isExpired ? 'Expired' : `Expires ${dayjs(nfd.timeExpires).fromNow()}`
                            }
                            color={expiresSoon ? 'red' : 'gray'}
                            className={clsxMerge(
                              expiresSoon ? 'font-bold' : '',
                              isList && 'origin-left mr-1.5 scale-90 shadow-sm'
                            )}
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <ResultTag label={`v${getNfdVersion(nfd)}`} color="gray" className="" />
                    )}
                  </p>
                </div>
                <div className="h-full flex items-center">{renderPrice()}</div>
                <div className="hidden 2xl:flex flex-wrap items-center">
                  <ResultTags tags={getTags(nfd, { premium: true })} tagClassName="my-0.5" />
                </div>
                <div className="hidden xl:flex items-center">
                  {isForSale ? (
                    <VaultInfo nfd={nfd} view="list" enableQuery={isList} />
                  ) : (
                    renderListSegmentsInfo()
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center w-10 xl:hidden">
              {isForSale ? (
                <VaultInfo nfd={nfd} view="listMobile" enableQuery={isList} />
              ) : (
                renderListSegmentsInfoMobile()
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  const linkClassName = isList
    ? 'block focus:outline-none focus:ring-inset focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-500 focus:ring-white hover:bg-gray-50/50 dark:bg-gray-900 dark:hover:bg-gray-850 dark:focus:ring-gray-900'
    : 'outline-brand-500 rounded-md group/result'

  if (!!activeWallet && isExpired) {
    return (
      <RenewDialog nfd={nfd} profileLink>
        <button
          type="button"
          className={clsxMerge('group block w-full text-left', linkClassName)}
          onMouseEnter={handleHover}
        >
          {renderContent()}
        </button>
      </RenewDialog>
    )
  }

  return (
    <Link href={`/name/${nfd.name}`} className={clsxMerge('group', linkClassName)}>
      {renderContent()}
    </Link>
  )
}
