import dynamic from 'next/dynamic'
import { useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { HiLockOpen } from 'react-icons/hi'
import { IoMdPricetag } from 'react-icons/io'
import UserThumbnail from 'components/UserThumbnail'
import SegmentsIcon from 'components/icons/Segments'
import type { NfdRecord } from 'api/api-client'

const Modal = dynamic(() => import('components/Modal'))

interface SegmentModalButtonProps {
  nfd: NfdRecord
  segmentPriceUsd: string
  segmentCount: number
  className?: string
  children: React.ReactNode
}

export default function SegmentModalButton({
  nfd,
  segmentPriceUsd,
  segmentCount,
  className = '',
  children
}: SegmentModalButtonProps) {
  const [isOpen, setIsOpen] = useState(false)
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const router = useRouter()

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(true)
  }

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(false)
  }

  const handleNavigation = () => {
    router.push(`/name/${nfd.name}?view=segments`)
  }

  return (
    <>
      <button type="button" className={className} onClick={handleOpen}>
        {children}
      </button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Segmenting Overview"
        icon={SegmentsIcon}
        initialFocus={closeButtonRef}
        showX={false}
        centered
        className="max-w-sm"
      >
        <div className="mt-6 flex justify-center">
          <UserThumbnail nfd={nfd} textSize="text-base" />
        </div>
        <p className="font-medium text-base text-gray-600 mt-4 space-y-1.5">
          <span className="flex items-center justify-center">
            <HiLockOpen className="h-4 w-4 mr-1.5 text-brand-500" />
            Unlocked
          </span>
          <span className="flex items-center justify-center">
            <IoMdPricetag className="h-4 w-4 mr-1.5 text-brand-500" />
            {`$ ${segmentPriceUsd}`}
          </span>
          <span className="flex items-center justify-center">
            <SegmentsIcon className="h-4 w-4 mr-1.5 text-brand-500" />
            {segmentCount} total
          </span>
        </p>

        <div className="mt-8">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-brand-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
            onClick={handleNavigation}
          >
            Go to Segments
          </button>
          <button
            ref={closeButtonRef}
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-gray-100"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}
