import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { captureException } from '@sentry/nextjs'
import { GetQuoteResponseBody, nfdGetQuote, NfdGetQuoteParams } from 'api/api-client'
import type { ICustomError } from 'api/customError'

export async function fetchQuote(
  name: string,
  params: NfdGetQuoteParams,
  options?: AxiosRequestConfig
) {
  const { data } = await nfdGetQuote(name, params, options)
  return data
}

interface UseGetSegmentArgs {
  name: string
  params: NfdGetQuoteParams
  options?: UseQueryOptions<GetQuoteResponseBody, ICustomError>
}

export function useGetQuote({ name, params, options }: UseGetSegmentArgs) {
  const query = useQuery({
    queryKey: ['mint-quote', { name, ...params }],
    queryFn: () => fetchQuote(name, params),
    ...options
  })

  if (query.error) {
    captureException(query.error)
  }

  return query
}
